@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow: scroll;
  height: 100%;
}

.ant-popover-inner {
  border-radius: 15px !important;
}

iframe {
  border-radius: 15px !important;
  /* height: 100% !important; */
  width: 100% !important;
}

.ant-menu-title-content {
  font-weight: 700 !important;
}

.ant-popover-inner {
  border-radius: 15px !important;
}

.ant-popover {
  z-index: 999 !important;
}
