.api-selector {
  border-radius: 10px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.api-item-option-content {
  font-size: 13px !important;
}

.api-selection-item {
  font-weight: 600 !important;
}
