.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
p {
  margin: 0px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.float-chat {
  right: 84px;
  bottom: 54px;
}
.float-dial {
  left: 34px !important;
  bottom: 54px !important;
}

.float-nonp-chat {
  right: 134px;
  bottom: 54px;
}

.float-cng {
  left: 84px !important;
  bottom: 54px !important;
}

.float-chat-list {
  left: 134px !important;
  bottom: 54px !important;
}

.cng-body {
  background-color: #fa8c16 !important;
}

.chat-list-body {
  background-color: #13c2c2 !important;
}

.nonp-chat-body {
  background-color: #6610f2 !important;
}

.menu {
  height: 6vh;
  align-items: center;
}
.sticky-footer {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
  color: #000;
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  width: 100% !important;
  bottom: 0;
}
.home-container {
  display: flex;
  background: white;
  justify-content: center;
  padding-top: 100px;
  align-items: center;
}
.home-col1 {
  object-fit: contain;
  width: 100%;
  height: 400px;
}
.home-col2 {
  font-size: 70px;
  font-weight: 500;
  text-align: start;
  margin-bottom: 20px;
}
.apply-button {
  border: none;
  background: #6610f2;
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.home-row2 {
  padding-block: 100px;
  align-items: center;
  background: white;
}
.home-col3 {
  align-items: flex-start;
  gap: 30px;
  display: flex;
  flex-direction: column;
  padding: 20px 25px !important;
}
.home-col3-p1 {
  word-wrap: break-word;
  font-weight: 700;
  font-size: 18px;
}
.home-col3-p2 {
  word-wrap: break-word;
  font-size: 29px;
  font-weight: 650;
  text-align: left;
}
.sign-up-button {
  border: 1px solid black;
  background: white;
  padding: 15px 18px;
  border-radius: 30px;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.home-col3-p3 {
  text-align: start;
  word-wrap: break-word;
  font-size: 17px;
  font-weight: 700;
}
.home-col3-p4 {
  margin-left: 22px;
  text-align: start;
  font-size: 15px;
  margin-top: 8px;
}
.home-col3-p5 {
  text-align: start;
  word-wrap: break-word;
  font-size: 17px;
  font-weight: 700;
}
.home-col3-p6 {
  text-align: start;
  word-wrap: break-word;
  font-size: 17px;
  font-weight: 700;
}
.home-col3-p7 {
  margin-left: 22px;
  text-align: start;
  font-size: 15px;
  margin-top: 8px;
}
.home-col3-p8 {
  margin-left: 22px;
  text-align: start;
  font-size: 15px;
  margin-top: 8px;
}
.home-div3 {
  display: flex;
  gap: 25px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  margin-top: 3vh;
}
.about-container {
  align-items: center;
  width: 87%;
  margin: auto;
}
.about-div2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-inline: 25px;
  gap: 40px;
}
.about-col2-p1 {
  font-size: 80px;
  font-weight: 600;
  text-align: left;
}
.contact-container {
  align-items: center;
  width: 75%;
  margin: auto;
}
.contact-div3 {
  width: 75%;
  margin: auto;
  text-align: left;
  padding-bottom: 10vh;
}
.contact-col1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
  width: 80%;
  padding-block: 30px;
  margin-top: 3%;
}
.about-col3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  width: 80%;
  padding-block: 30px;
}

.glassy {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.loading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.call-btn-body {
  background-color: #4cbb17 !important;
  transition: all 0.3s !important;
}

.call-btn-body:hover {
  background-color: rgba(76, 187, 23, 0.8) !important;
}

.call-btn-icon {
  margin-right: 1px !important;
}

.ant-message {
  top: 70px !important;
  font-weight: 600 !important;
}
